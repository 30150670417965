.node-type-espace-pro {
  #mainTitle {
    padding-top: 32px;
    padding-bottom: 16px;
    background-color: #f7f6f4;

    .container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media (min-width: @screen-sm-min) {
        flex-direction: row;
        align-items: center;

        h1 {
          flex: 1 0 0;
        }
      }

      &.md-width {
        max-width: 1000px;
      }

      &:before,
      &:after {
        display: none;
      }
    }

    h1 {
      float: none;
      margin-top: 0;
      margin-bottom: 0;
    }

    .breadcrumbWrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 4px;
      float: none;
      margin-top: 0;
      margin-bottom: 0;
      font-size: inherit;
      max-width: none;
      text-align: left;

      > span {
        display: block;
        vertical-align: baseline;
        margin-right: 0;
        text-align: left;
      }
    }

    .breadcrumb {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 4px;
      vertical-align: baseline;
      font-size: inherit;

      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 4px;
        text-align: left;

        & + li:before {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

#espace-pro {
  .container {
    &.md-width {
      max-width: 1000px;
    }
  }

  .espace-pro--intro {
    padding-top: 32px;
    padding-bottom: 80px;
    background-color: #f7f6f4;

    .container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (min-width: @screen-sm-min) {
        flex-direction: row;

        > * {
          flex: 1 0 0;
        }
      }

      &:before,
      &:after {
        display: none;
      }
    }

    .intro {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      p {
        @media (min-width: @screen-sm-min) {
          max-width: 346px;
        }
      }
    }

    .login {
      form {
        > div {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          gap: 17px;

          @media (min-width: @screen-sm-min) {
            border-left: 1px solid #e8e8e8;
            padding-left: 36px;
            padding-right: 36px;
          }

          @media (min-width: @screen-md-min) {
            padding-left: 72px;
            padding-right: 72px;
          }
        }

        .form-group {
          margin-bottom: 0 !important;

          &:not(.form-actions) {
            width: 100%;
          }
        }

        .form-control {
          border-color: #c6c6c6;
          padding: 5px 16px;
          height: auto;
          font-size: 1rem;
          line-height: 1.875;

          &::placeholder {
            color: @brand-primary;
          }
        }

        .register {
          display: none;
        }

        .lost {
          order: 1;
          font-size: 0.875rem;
          line-height: 1.8572;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        .form-submit {
          padding-top: 11px;
          padding-bottom: 11px;
          font-size: 0.875rem;
          font-weight: 700;
          line-height: 1.4286;
        }
      }
    }
  }

  .espace-pro--texte {
    padding-top: 80px;
    padding-bottom: 80px;
    line-height: 1.5;

    .row {
      display: flex;
      flex-direction: column;
      gap: 36px 72px;
      margin-left: 0;
      margin-right: 0;

      &:before,
      &:after {
        display: none;
      }

      > div {
        position: static;
        float: none;
        padding-left: 0;
        padding-right: 0;
        width: auto;
        min-height: auto;
      }

      @media (min-width: @screen-md-min) {
        flex-direction: row;

        > div {
          &:nth-child(1) {
            flex-shrink: 0;
            width: 488px;

            p {
              max-width: 346px;
            }
          }

          &:nth-child(2) {
            flex: 1 0 0;
          }
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border: 2px solid @brand-secondary;
      border-radius: 4px;
      padding: 18px 24px;
      list-style: none;

      li {
        position: relative;
        padding-left: 21px;
        color: @brand-secondary;
        font-weight: 700;

        &::before {
          content: "";
          position: absolute;
          top: 5px;
          left: 0;
          width: 13px;
          height: 13px;
          background-image: url(../img/icon-check.png);
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    p {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn {
      padding: 11px 16px;
      min-width: 152px;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.4286;
    }
  }

  .espace-pro--cta {
    padding-top: 10px;
    padding-bottom: 24px;
    line-height: 1.5;

    .box {
      display: flex;
      flex-direction: column;
      gap: 32px 64px;
      padding: 48px 24px;
      background-color: #eee;

      @media (min-width: @screen-sm-min) {
        flex-direction: row;
        align-items: center;

        .text {
          flex: 1 0 0;
        }
      }

      @media (min-width: @screen-md-min) {
        padding-left: 48px;
        padding-right: 48px;
      }
    }

    strong {
      color: @brand-abo1;
    }

    .btn {
      padding: 11px 16px;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.4286;
    }
  }

  .espace-pro--formules {
    padding-top: 80px;
    padding-bottom: 24px;

    table {
      border-collapse: collapse;
      caption-side: bottom;
    }

    th {
      text-align: inherit;
      text-align: -webkit-match-parent;
    }

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      border-color: inherit;
      border-style: solid;
      border-width: 0;
    }

    table {
      vertical-align: top;
      margin-bottom: 0;
      border-color: #d5d5d5;
      width: 100%;
      table-layout: fixed;

      > :not(caption) > * > * {
        border-bottom-width: 1px;
        padding: 24px 12px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      > tbody {
        vertical-align: inherit;

        > *:last-child {
          > * {
            border-bottom-width: 0;
          }
        }
      }

      > thead {
        vertical-align: top;
      }
    }

    table,
    .mobile-table {
      .cell {
        color: @brand-primary;
        font-size: 0.875rem;
        line-height: 1.5;

        &.font-lg {
          font-size: 1rem;
        }

        .title {
          display: flex;
          flex-direction: column;
          margin-bottom: 18px;
          border-top: 3px solid @brand-primary;
          padding-top: 6px;
          color: @brand-primary;
          font-size: 1.125rem;
          font-weight: 700;
          line-height: 1.6667;

          &:last-child {
            margin-bottom: 0;
          }

          > span {
            text-transform: uppercase;
          }

          strong {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.3;

            span {
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5;
            }
          }
        }

        .btn {
          padding: 8px 16px;
          font-size: 0.875rem;
          font-weight: 700;
          line-height: 1.4286;
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-bottom: 12px;
          padding-left: 0;
          list-style: none;

          &:last-child {
            margin-bottom: 0;
          }

          li {
            position: relative;
            padding-left: 21px;

            &::before {
              content: "";
              position: absolute;
              top: 4px;
              left: 0;
              width: 13px;
              height: 13px;
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 14'%3e%3cpath fill='%23382527' d='M3.5 6.5L0 9C1.2 9 4.16667 12 5.5 13.5C5.5 13.5 9 6 13 6V0.5C13 0.5 7.5 3.5 5 9.5L3.5 6.5Z'/%3e%3c/svg%3e");
              background-position: 50%;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
        }

        a:not(.btn):not(.link) {
          color: inherit;
          font-weight: 700;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        .link {
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }

        &.free {
          &.font-lg {
            color: @brand-abo1;
          }

          .title {
            border-color: @brand-abo1;
            color: @brand-abo1;
          }

          .btn {
            background-color: @brand-abo1;

            &:hover {
              background-color: darken(@brand-abo1, 15%);
            }
          }

          ul {
            li {
              &::before {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 14'%3e%3cpath fill='%232fa500' d='M3.5 6.5L0 9C1.2 9 4.16667 12 5.5 13.5C5.5 13.5 9 6 13 6V0.5C13 0.5 7.5 3.5 5 9.5L3.5 6.5Z'/%3e%3c/svg%3e");
              }
            }
          }

          .link {
            color: @brand-abo1;
          }
        }

        &.premium {
          &.font-lg {
            color: @brand-abo2;
          }

          .title {
            border-color: @brand-abo2;
            color: @brand-abo2;
          }

          .btn {
            background-color: @brand-abo2;

            &:hover {
              background-color: darken(@brand-abo2, 15%);
            }
          }

          ul {
            li {
              &::before {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 14'%3e%3cpath fill='%238c8082' d='M3.5 6.5L0 9C1.2 9 4.16667 12 5.5 13.5C5.5 13.5 9 6 13 6V0.5C13 0.5 7.5 3.5 5 9.5L3.5 6.5Z'/%3e%3c/svg%3e");
              }
            }
          }

          .link {
            color: @brand-abo2;
          }
        }

        &.booster {
          &.font-lg {
            color: @brand-abo3;
          }

          .title {
            border-color: @brand-abo3;
            color: @brand-abo3;
          }

          .btn {
            background-color: @brand-abo3;

            &:hover {
              background-color: darken(@brand-abo3, 15%);
            }
          }

          ul {
            li {
              &::before {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 14'%3e%3cpath fill='%23382527' d='M3.5 6.5L0 9C1.2 9 4.16667 12 5.5 13.5C5.5 13.5 9 6 13 6V0.5C13 0.5 7.5 3.5 5 9.5L3.5 6.5Z'/%3e%3c/svg%3e");
              }
            }
          }

          .link {
            color: @brand-abo3;
          }
        }

        &.max {
          &.font-lg {
            color: @brand-abo4;
          }

          .title {
            border-color: @brand-abo4;
            color: @brand-abo4;
          }

          .btn {
            background-color: @brand-abo4;

            &:hover {
              background-color: darken(@brand-abo4, 15%);
            }
          }

          ul {
            li {
              &::before {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 14'%3e%3cpath fill='%23dca200' d='M3.5 6.5L0 9C1.2 9 4.16667 12 5.5 13.5C5.5 13.5 9 6 13 6V0.5C13 0.5 7.5 3.5 5 9.5L3.5 6.5Z'/%3e%3c/svg%3e");
              }
            }
          }

          .link {
            color: @brand-abo4;
          }
        }
      }
    }

    table {
      display: none;

      @media (min-width: @screen-sm-min) {
        display: table;
      }

      .cell {
        .title {
          min-height: 91px;
        }
      }
    }

    .mobile-table {
      @media (min-width: @screen-sm-min) {
        display: none;
      }

      .cell {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }

  .espace-pro--infos {
    padding-top: 42px;
    padding-bottom: 80px;

    .infos-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-bottom: 8px;
      border-top: 1px solid #d5d5d5;
      padding-top: 24px;
      padding-bottom: 24px;
      color: @brand-primary;
      font-size: 0.875rem;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .btn {
        padding: 12px 16px;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.4286;
      }
    }

    .infos {
      .box {
        display: flex;
        flex-direction: column;
        gap: 22px;
        margin-bottom: 8px;
        border-radius: 4px;
        padding: 24px;
        background-color: #eee;

        @media (min-width: @screen-sm-min) {
          flex-direction: row;
          align-items: center;

          .text {
            flex: 1 0 0;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        h2 {
          color: @brand-primary;
        }
      }
    }

    .payment {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-top: 8px;
      padding-bottom: 8px;

      @media (min-width: @screen-md-min) {
        flex-direction: row;
        align-items: center;

        span {
          flex: 1 0 0;
        }
      }

      .logos {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 24px;

        .logo {
          img {
            vertical-align: middle;
            width: auto;
            height: 40px;
          }

          &.logo-sm {
            img {
              height: 32px;
            }
          }
        }
      }
    }

    .discount {
      display: flex;
      flex-direction: column;
      gap: 22px;
      border-radius: 4px;
      padding: 64px 24px;
      background-color: #eee;
      text-align: center;

      @media (min-width: @screen-md-min) {
        padding-left: 80px;
        padding-right: 80px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      h2 {
        color: @brand-primary;

        strong {
          color: @brand-abo1;
        }
      }

      .btn {
        padding: 12px 16px;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.4286;
      }
    }
  }

  .espace-pro--testimonials {
    margin-bottom: 8px;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #f7f6f4;

    .container {
      display: flex;
      flex-direction: column;
      gap: 36px;

      &:before,
      &:after {
        display: none;
      }
    }

    .title {
      text-align: center;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 0;
        color: #000;
        line-height: 1.5;

        &:last-child {
          margin-bottom: 0;
        }
      }

      h2 {
        font-size: 1.5rem;
      }
    }

    .swiper {
      width: 100%;
      height: auto;
    }

    .testimonial {
      display: flex;
      flex-direction: column;
      gap: 36px;
      border: 2px solid @brand-secondary;
      padding: 48px 24px;
      border-radius: 4px;
      background-color: #fff;

      @media (min-width: @screen-xs-min) {
        padding-left: 40px;
        padding-right: 40px;
      }

      @media (min-width: @screen-sm-min) {
        flex-direction: row;
        align-items: flex-start;
      }

      @media (min-width: @screen-md-min) {
        column-gap: 72px;
        padding-left: 80px;
        padding-right: 80px;
      }

      .author {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;

        @media (min-width: @screen-sm-min) {
          width: 144px;
        }

        .pp {
          position: relative;
          border-radius: 50%;
          width: 144px;
          max-width: 100%;
          height: 144px;
          overflow: hidden;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        span {
          color: #000;
          font-size: 1rem;
          font-weight: 700;
          line-height: 1.5;
          text-align: center;
        }
      }

      .text {
        flex: 1 0 0;
        color: #000;
        font-size: 1rem;
        font-style: italic;
        font-weight: 400;
        line-height: 1.5;
      }
    }

    .custom-pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        top: auto;
        bottom: auto;
        left: auto;
        width: auto;

        .swiper-pagination-bullet {
          margin: 0;
        }
      }

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 36px;

      .swiper-pagination-bullet {
        display: block;
        border: 1px solid #000;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background: transparent;
        opacity: 1;
      }

      .swiper-pagination-bullet-active {
        border-color: @brand-primary;
        background: @brand-primary;
        opacity: 1;
      }

      &.swiper-pagination-lock {
        display: none;
      }
    }
  }
}

body.page-user {
  #main {
    form {
      padding-bottom: 25px;
      &.membership-entity-join-form {
        .attribute-widgets,
        #edit-line-item-fields,
        .membership-entity-term-info {
          display: none;
        }
      }
      .field-name-field-user-naissance {
        .date-no-float {
          width: 100%;
        }
        .container-inline-date {
          & > .form-item,
          .form-group {
            margin: 0 !important;
          }
          .form-item {
            max-width: 100%;
          }
          input {
            margin: 0;
          }
        }
        #edit-field-user-naissance-und-0-value {
          float: none;
          padding: 0;
        }
      }
      .field-type-datetime {
        fieldset {
          padding: 0;
          legend {
            margin-bottom: 10px;
            color: @brand-primary;
            font-size: 1.13rem;
            font-weight: 700;
          }
        }
        .start-date-wrapper,
        .end-date-wrapper {
          float: left;
          width: auto;
          @media (max-width: @screen-xs-max) {
            float: none;
          }
          .form-type-date-select {
            margin: 0 !important;
            > label {
              float: left;
              margin-right: 15px;
              @media (max-width: @screen-xs-max) {
                margin: 15px 0;
              }
            }
            .date-padding {
              padding: 0;
              .form-type-select {
                margin: 0 !important;
                label {
                  margin: 0 0 5px;
                  font-weight: 400;
                }
                & + .form-type-select {
                  margin-left: 5px !important;
                }
              }
            }
          }
        }
        .end-date-wrapper {
          clear: none;
          margin-left: 35px;
          @media (max-width: @screen-xs-max) {
            margin-left: 0;
          }
        }
      }

      #edit-infos {
        .paiements {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;

          .paiement + .paiement {
            margin-left: 10px;
          }
        }
      }
    }
    .btn-success {
      .btn;
      .btn-primary;
    }
  }
  .profile-psy {
    .item.item--events.item-large {
      border-bottom: 0;
    }
    .profile-psy-info {
      .profil {
        padding-top: 20px;
        padding-bottom: 25px;
        border-bottom: 1px solid #f0f0f0;
        .avatar {
          img {
            width: 134px;
            background-color: #fff;
            border: 2px solid #f7f6f4;
            border-radius: 50%;
          }
          @media (max-width: @screen-xs-max) {
            margin-bottom: 20px;
          }
        }
        .nom {
          h2 {
            margin: 0;
            color: @brand-primary;
            font-size: 1.5rem;
            line-height: 1.25em;
          }
          h3 {
            margin: 0;
            color: @brand-secondary;
            font-size: 1.13rem;
            line-height: 1.67em;
          }
          .btns {
            margin-top: 20px;
          }
          .btn {
            padding-left: 25px;
            padding-right: 25px;
            @media (max-width: @screen-xs-max) {
              display: block;
            }
            & + .btn {
              margin-left: 5px;
              @media (max-width: @screen-xs-max) {
                margin-top: 8px;
                margin-left: 0;
              }
            }
            &.btn-cta-green {
              background: @brand-success;
              color: #fff;
            }
          }
        }
        .presentation {
          margin: 20px 0 0;
          padding: 0 !important;
          border: 0 !important;
        }
      }
      .coord {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #f0f0f0;
        p {
          .clearfix;
          margin: 0;
          strong {
            display: block;
            float: left;
            padding-right: 15px;
            width: 30%;
            text-align: right;
            @media (max-width: @screen-xs-max) {
              float: none;
              padding-right: 0;
              width: 100%;
              text-align: left;
            }
          }
          span {
            display: block;
            float: left;
            width: 70%;
            @media (max-width: @screen-xs-max) {
              float: none;
              width: 100%;
            }
          }
          a {
            color: @text-color;
            &.btn-phone {
              background: @brand-secondary;
              color: #fff;
              padding: 5px 10px;
              font-weight: bold;
              text-transform: none;
              &:hover {
                background: @brand-primary;
                color: #fff;
                -webkit-transition: all 2s;
                -moz-transition: all 2s;
                transition: all 2s;
              }
            }
            &.phone-call {
              background: @brand-secondary;
              padding: 5px 10px;
              font-weight: bold;
              text-transform: none;
              border-radius: 3px;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
        .address,
        .consultationHours {
          p {
            strong,
            span {
              width: auto;
              text-align: left;
            }
          }
        }
        .consultationHours {
          margin-top: 30px;
        }
      }
      .presentation {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #f0f0f0;
        p {
          line-height: 1.5em;
        }
      }
      .update {
        margin-top: 25px;
      }
    }
  }
  ul.tabs--primary {
    margin: 0 0 25px;
    padding: 0;
    list-style: none;
    border: 0;
    li {
      display: block;
      position: relative;
      float: left;
      margin: 2px;
      @media (max-width: @screen-xs-max) {
        float: none;
        margin: 2px 0;
      }
      a {
        display: block;
        position: relative;
        margin: 0;
        padding: 10px 4px;
        background-color: #f2f1f1;
        border: 0 !important;
        border-radius: 0;
        color: @brand-primary;
        font-size: 0.88rem;
        line-height: normal;
        span {
          display: block;
          position: absolute;
          top: 4px;
          right: 4px;
          height: 20px;
          width: 20px;
          background-color: @brand-secondary;
          border-radius: 50%;
          color: #fff;
          font-size: 0.69rem;
          line-height: 20px;
          text-align: center;
        }
        &.notification {
          padding-right: 28px;
        }
        &:hover {
          background-color: transparent;
          color: @brand-secondary;
        }
      }
      &.active {
        a {
          background-color: transparent;
          color: @brand-secondary;
        }
      }
    }
    & + h2 {
      margin-top: 0;
      margin-bottom: 25px;
    }
  }
  .psy-message {
    h2 {
      margin-top: 0;
      margin-bottom: 25px;
      span {
        color: @brand-primary;
        font-size: 0.88rem;
      }
    }
    .author {
      font-size: 1.13rem;
      line-height: 1em;
      span {
        font-size: 0.88rem;
      }
    }
    .coord,
    .psy-consult {
      margin-top: 20px;
      padding: 20px 0;
      border-top: 1px solid #f0f0f0;
      p {
        .clearfix;
        margin: 0;
        strong {
          display: block;
          float: left;
          padding-right: 15px;
          width: 30%;
          text-align: right;
          @media (max-width: @screen-xs-max) {
            float: none;
            padding-right: 0;
            width: 100%;
            text-align: left;
          }
        }
        span {
          display: block;
          float: left;
          width: 70%;
          @media (max-width: @screen-xs-max) {
            float: none;
            width: 100%;
          }
        }
        a {
          color: @text-color;
        }
      }
    }
    .message {
      margin-top: 20px;
      padding: 20px;
      background-color: #f7f7f7;
    }
  }
  .psy-comment {
    .clearfix;
    padding: 30px 0;
    border-bottom: 1px solid #f0f0f0;
    .avatar,
    .content-wrapper {
      float: left;
    }
    .avatar {
      img {
        display: block;
        height: 40px;
        width: 40px;
        background-color: #f2f1f1;
        border-radius: 50%;
      }
    }
    .content-wrapper {
      padding: 0 15px;
      width: calc(~"100% - 40px");
      h3 {
        margin: 0;
      }
      .submitted {
        margin: 10px 0 0;
        color: @brand-primary;
        font-size: 0.75rem;
        font-weight: 700;
      }
      ul {
        margin: 0;
      }
    }
  }
  .view-informations-pro {
    > h2,
    > .view-filters {
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
      margin-bottom: 25px;
      @media (max-width: @screen-xs-max) {
        display: block;
      }
    }
    > .view-filters {
      margin-left: 15px;
      @media (max-width: @screen-xs-max) {
        margin-left: 0;
      }
      form {
        padding: 0 !important;
      }
      .form-group {
        margin-bottom: 0 !important;
      }
    }
  }
  .alacarte {
    > h2 {
      margin: 25px 0 35px;
    }
  }
  #alaune {
    // margin-bottom: -180px;
    // padding-top: 40px;
    // border-bottom: 230px solid #f2f1f1;
    .item--alaune {
      margin: 0;
      .imgWrapper {
        // margin-bottom: -50px;
        @media (max-width: @screen-xs-max) {
          margin-bottom: 0;
        }
      }
    }
  }
  #alaune--bienetre {
    margin-top: 30px;
    margin-bottom: 10px;
    border-bottom: 275px solid @brand-green;
    .item--conseil-bienetre {
      margin: 0;
      margin-bottom: -240px;
    }
  }
  #evidence {
    padding-top: 10px;
  }
  #stripe-subscription-subscription-form {
    .form-text,
    .form-select {
      .form-control;
    }
  }
  &.page-user-consultation,
  &.page-user-forum,
  &.page-user-stats,
  &.page-user-subscription,
  &.page-user-edit {
    table {
      tr {
        td,
        th {
          padding: 11px 15px !important;
          font-size: 0.88rem;
        }
        th {
          img {
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

#comments {
  .comment-form {
    .control-label {
      display: none;
    }
  }
  .comment {
    .content-wrapper {
      > .new {
        padding: 2px 5px;
        background-color: @brand-success;
        border-radius: 3px;
        color: #fff;
      }
      > h3 {
        display: none;
      }
    }
  }
}

.page-user-subscription {
  #stripe-subscription-subscription-form {
    #edit-subscription {
      .form-item-plan-id {
        margin: 30px 0 0 !important;
        padding: 0 30px;
      }
    }
    #edit-payment {
      padding: 30px;
      border: 1px solid #d7d7d7;
    }
  }
  #psy-formules-form {
    .form-item-conditions {
      clear: both;
    }
  }
}
