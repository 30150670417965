// Header
#header {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  .transition(all 0.35s);
  .container {
    position: relative;
  }
  .logo {
    display: block;
    float: left;
    margin-top: 50px;
    padding: 18px 8px;
    .transition(all 0.35s);
    @media (max-width: @screen-sm-max) {
      margin-top: 0;
    }
    svg {
      display: block;
    }
  }
  .mainnav {
    float: right;
    margin-top: 60px;
    padding: 20px 8px;
    .transition(all 0.35s);
    @media (max-width: @screen-sm-max) {
      float: none;
      margin-top: 0;
    }
    > ul {
      float: none;
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        float: left;
        @media (max-width: @screen-sm-max) {
          float: none;
        }
        > a,
        > .nolink {
          display: block;
          padding: 0;
          background-color: transparent;
          color: @brand-primary;
          font-size: 0.94rem;
          font-weight: 700;
          line-height: 2em;
          .transition(all 0.2s);
          > .caret {
            display: inline-block;
            vertical-align: top;
            margin: 0;
            height: auto;
            width: auto;
            border: 0;
            &:before {
              content: "\f107";
              font-family: "FontAwesome";
              font-size: 0.75rem;
              line-height: 1em;
              font-weight: 400;
            }
          }
          @media (max-width: @screen-sm-max) {
            color: #fff;
            padding: 10px 0;
          }
        }
        &:hover,
        &.active,
        &.active-trail,
        &.open {
          > a,
          > .nolink {
            color: @brand-secondary;
          }
        }
        & + li {
          margin-left: 20px;
          @media (max-width: @screen-sm-max) {
            margin-left: 0;
          }
        }
        &.pro {
          margin-left: 10px;
          @media (max-width: @screen-sm-max) {
            margin-top: 20px;
            margin-left: 0;
          }
          > a,
          > .nolink {
            .btn;
            .btn-primary;
            display: block;
            padding: 0 10px;
            font-size: 0.94rem;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 2em;
            text-transform: none;
            -webkit-box-shadow: none !important;
            -moz-box-shadow: none !important;
            box-shadow: none !important;
            @media (max-width: @screen-sm-max) {
              padding-top: 10px;
              padding-bottom: 10px;
              background-color: #fff;
              color: @brand-primary;
            }
          }
          &:hover,
          &.active,
          &.active-trail,
          &.open {
            > a,
            > .nolink {
              background-color: @brand-secondary;
              @media (max-width: @screen-sm-max) {
                color: #fff;
              }
            }
          }
        }
        ul.dropdown-menu {
          position: absolute;
          z-index: 1000;
          top: 100%;
          left: 0;
          float: left;
          margin: 0;
          padding: 0;
          min-width: 150px;
          list-style: none;
          background-color: #fff;
          border: 1px solid #f0f0f0;
          border-radius: 0;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          @media (max-width: @screen-sm-max) {
            position: relative;
            top: auto;
            left: auto;
            float: none;
            text-align: center;
          }
          ul.dropdown-menu {
            top: 0;
            left: 100%;
            @media (max-width: @screen-sm-max) {
              display: block;
              top: auto;
              left: auto;
              background-color: @brand-primary;
            }
          }
        }
        > ul.dropdown-menu {
          > li {
            > a,
            > .nolink {
              display: block;
              position: relative;
              margin: 0;
              padding: 5px 15px;
              background-color: transparent;
              color: @brand-primary;
              font-size: 0.88rem;
              font-weight: 700;
              line-height: 2.14em;
              white-space: normal;
              .transition(all 0.2s);
              > .caret {
                display: block;
                position: absolute;
                top: 50%;
                right: 10px;
                margin: 0;
                margin-top: -6px;
                height: auto;
                width: auto;
                border: 0;
                &:before {
                  content: "\f105";
                  display: block;
                  font-family: "FontAwesome";
                  font-size: 0.75rem;
                  line-height: 1em;
                  font-weight: 400;
                }
                @media (max-width: @screen-sm-max) {
                  display: none;
                }
              }
            }
            & + li {
              border-top: 1px solid #f0f0f0;
            }
            &:hover,
            &.active,
            &.active-trail,
            &.open {
              > a,
              > .nolink {
                background-color: @brand-secondary;
                color: #fff;
              }
            }
            &:hover {
              > ul.dropdown-menu {
                display: block;
              }
            }
            > ul.dropdown-menu {
              > li {
                > a,
                > .nolink {
                  display: block;
                  margin: 0;
                  padding: 0 20px;
                  background-color: transparent;
                  color: @brand-primary;
                  font-size: 0.88rem;
                  font-weight: 400;
                  line-height: 2.14em;
                  white-space: normal;
                  .transition(all 0.2s);
                  @media (max-width: @screen-sm-max) {
                    color: #fff;
                  }
                }
                &:hover,
                &.active,
                &.active-trail,
                &.open {
                  > a,
                  > .nolink {
                    color: @brand-secondary;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #topbar {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    padding: 0 15px;
    width: 100%;
    background-color: @brand-primary;
    color: #fff;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 9999px;
      background-color: @brand-primary;
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
    @media (max-width: @screen-sm-max) {
      position: relative;
      top: auto;
      left: auto;
      background-color: transparent;
      &:before,
      &:after {
        display: none;
      }
    }
  }
  .form-control {
    padding: 0 10px;
    height: 25px;
    width: 100%;
    min-width: 145px;
    background-color: #4c3b3d;
    border: 0;
    border-radius: 4px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    @media (max-width: @screen-sm-max) {
      background-color: #fff;
    }
  }
  .form-control,
  output {
    display: block;
    color: #fff;
    font-size: 0.75rem;
    line-height: normal;
    @media (max-width: @screen-sm-max) {
      color: #4c3b3d;
    }
  }
  button[type="submit"]:not(.element-invisible) {
    display: block;
    margin-left: 5px;
    padding: 0 10px;
    height: 25px;
    background-color: #210e11;
    @media (max-width: @screen-xs-max) {
      margin-left: 0;
      width: 100%;
    }
    &:hover {
      background-color: @brand-secondary;
    }
  }
  ::-webkit-input-placeholder {
    color: #fff;
    @media (max-width: @screen-sm-max) {
      color: #4c3b3d;
    }
  }

  :-moz-placeholder {
    color: #fff;
    @media (max-width: @screen-sm-max) {
      color: #4c3b3d;
    }
  }

  ::-moz-placeholder {
    color: #fff;
    @media (max-width: @screen-sm-max) {
      color: #4c3b3d;
    }
  }

  :-ms-input-placeholder {
    color: #fff;
    @media (max-width: @screen-sm-max) {
      color: #4c3b3d;
    }
  }
  .login {
    position: relative;
    float: left;
    padding: 7px 8px 8px;
    padding-right: 60px;
    @media (max-width: @screen-sm-max) {
      float: none;
      margin-top: 30px;
      padding-right: 8px;
    }
    form {
      > div {
        .form-group,
        > a,
        #edit-actions {
          float: left;
          @media (max-width: @screen-sm-max) {
            display: inline-block;
            float: none;
          }
          @media (max-width: @screen-xs-max) {
            display: block;
          }
        }
        .form-group {
          margin: 0 !important;
          + .form-group {
            margin-left: 7px !important;
            @media (max-width: @screen-xs-max) {
              margin-top: 7px !important;
              margin-left: 0 !important;
            }
          }
        }
        > a {
          display: block;
          position: absolute;
          right: 0;
          margin-left: 15px;
          color: #fff;
          font-size: 0.75rem;
          line-height: 2.09em;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
          &.lost {
            display: none;
          }
          @media (max-width: @screen-sm-max) {
            display: inline-block;
            position: relative;
            right: auto;
          }
        }
        .form-actions {
          clear: none;
        }
      }
    }
    .welcome {
      color: #fff;
      font-size: 0.75rem;
      line-height: 2.09em;
    }
  }
  .nav {
    float: right;
    padding: 7px 8px 8px;
    @media (max-width: @screen-sm-max) {
      float: none;
    }
  }
  #searchBox,
  .search,
  .topnav,
  .language,
  .social {
    float: left;
    @media (max-width: @screen-sm-max) {
      float: none;
    }
  }
  #searchBox {
    @media (max-width: @screen-sm-max) {
      margin-top: 30px;
    }
    .contextual-links-wrapper {
      display: none;
    }
    .input-group {
      .clearfix;
      .input-group,
      .input-group-btn {
        float: left;
        width: auto;
        @media (max-width: @screen-sm-max) {
          float: none;
          display: inline-block;
        }
        @media (max-width: @screen-xs-max) {
          display: block;
        }
      }
      .input-group-addon {
        display: none;
      }
      @media (max-width: @screen-xs-max) {
        .input-group-btn {
          margin-top: 5px;
        }
      }
    }
    .form-group {
      margin: 0 !important;
    }
  }
  .search {
    // display: block;
    display: none;
    padding: 0;
    background-color: transparent;
    color: #fff;
    font-size: 0.75rem;
    line-height: 2.09em;
    text-decoration: none;
    .fa {
      display: block;
      float: left;
      margin-right: 10px;
      font-size: 1rem;
      line-height: 1.57em;
    }
    &:hover,
    &.active {
      color: @brand-secondary;
    }
  }
  .language,
  .topnav {
    margin-left: 10px;
    @media (max-width: @screen-sm-max) {
      margin-top: 30px;
      margin-left: 0;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        float: left;
        @media (max-width: @screen-sm-max) {
          float: none;
        }
        a,
        span {
          display: block;
          padding: 0;
          background-color: transparent;
          color: #fff;
          font-size: 0.75rem;
          line-height: 2.09em;
          text-decoration: none;
        }
        a {
          &:hover,
          &.active {
            color: @brand-secondary;
          }
        }
        span {
          cursor: default;
        }
        & + li {
          margin-left: 10px;
          @media (max-width: @screen-sm-max) {
            margin-left: 0;
          }
        }

        &.cta {
          a,
          span {
            border-radius: 4px;
            padding-left: 8px;
            padding-right: 8px;
            background-color: #ffd1d8;
            color: #000;
            .transition(all 0.2s);
          }

          a {
            &:hover {
              background-color: @brand-secondary;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .language {
    //display: none;
    margin-left: 25px;
    @media (max-width: @screen-sm-max) {
      margin-top: 30px;
      margin-left: 0;
    }
    ul {
      li {
        a {
          text-transform: uppercase;
        }
        & + li {
          margin-left: 5px;
          @media (max-width: @screen-sm-max) {
            margin-left: 0;
          }
        }
      }
    }
  }
  .social {
    margin-left: 20px;
    @media (max-width: @screen-sm-max) {
      margin-top: 30px;
      margin-left: 0;
    }
    > a {
      float: left;
      display: block;
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.57em;
      @media (max-width: @screen-sm-max) {
        display: inline-block;
        float: none;
      }
      &:hover {
        color: @brand-secondary;
      }
      & + a {
        margin-left: 10px;
      }
    }
  }
  &.scrolled {
    top: -40px;
    border-bottom: 1px solid #f0f0f0;
    @media (max-width: @screen-sm-max) {
      top: 0;
    }
    .logo,
    .mainnav {
      margin-top: 40px;
      @media (max-width: @screen-sm-max) {
        margin-top: 0;
      }
    }
  }
}

body.not-front {
  #header {
    border-bottom: 1px solid #f0f0f0;
  }
}

.trigger-menu {
  position: relative;
  z-index: 1;
  float: right;
  padding: 18px 8px;
  svg {
    display: block;
    fill: @brand-primary !important;
    rect {
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -ms-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
      -webkit-transform: rotate(0);
      -moz-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
      .transition(all 0.2s);
    }
  }
  &.open {
    svg {
      rect {
        fill: #fff !important;
        &:nth-child(1) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          x: 3px;
        }
        &:nth-child(2) {
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3) {
          -webkit-transform: rotate-(45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          x: 3px;
        }
      }
    }
  }
}

@media (max-width: @screen-sm-max) {
  nav#nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 30px 0;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    background-color: fade(@brand-primary, 90%);
    overflow-x: auto;
    overflow-y: scroll;
    .transition(all 0.35s);
  }
}

body.open-menu {
  @media (max-width: @screen-sm-max) {
    height: 100%;
    overflow: hidden;
    nav#nav {
      visibility: visible;
      opacity: 1;
    }
  }
}

#mainTitle {
  .clearfix;
  h1,
  .h1 {
    float: left;
    @media (max-width: @screen-xs-max) {
      float: none;
    }
  }
  .breadcrumbWrapper {
    float: right;
    margin-top: 40px;
    font-size: 0;
    max-width: 600px;
    text-align: right;
    @media (max-width: @screen-xs-max) {
      float: none;
      margin-top: 0;
      margin-bottom: 30px;
      text-align: left;
    }
    > span {
      display: inline-block;
      vertical-align: top;
      margin-right: 3px;
      font-size: 0.75rem;
      line-height: 1.17em;
    }
  }
}

.breadcrumb {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
  font-size: 0;
  li {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.17em;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    & + li {
      &:before {
        content: ">";
        padding: 0 5px;
        color: #1f1f1f;
      }
    }
    &.active {
      color: #1f1f1f;
    }
  }
}

body.admin-menu.adminimal-menu {
  #header {
    margin-top: 29px;
  }
}
.fixed-cta {
  position: fixed;
  right: 0;
  top: 250px;
  z-index: 10;
  width: 250px;
  margin-right: -162px;
  text-align: left;
  .transition(all 0.2s);
  .fixed-link {
    display: inline-block;
    padding: 5px 20px;
    color: @brand-primary;
    font-weight: bold;
    background: #ffd1d8;
    text-decoration: none;
    transform: rotate(-90deg);
    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }
  }
  .toggle-box {
    visibility: hidden;
    opacity: 0;
    background: lighten(@brand-primary, 10%);
    text-align: left;
    padding: 10px 20px;
    ul {
      padding: 0;
      li {
        color: #fff;
        list-style: none;
        a {
          color: #fff;
          &:before {
            content: "\f0da";
            font-family: FontAwesome;
            margin-right: 10px;
            font-size: 0.8rem;
            display: inline-block;
          }
          &:hover {
            &:before {
              text-decoration: none;
            }
          }
        }
      }
      &.pro {
        background: #ffd1d8;
        li {
          a {
            color: @brand-primary;
            text-align: center;
            display: block;
            line-height: 1.4rem;
            font-weight: bold;
            padding: 3px 20px;
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
  &.open {
    text-align: center;
    margin-right: 0;
    .fixed-link {
      display: block;
      transform: rotate(0deg);
      .transition(all 0.2s);
    }
    .toggle-box {
      visibility: visible;
      opacity: 1;
    }
  }
}
