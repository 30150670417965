// Conseil
body.page-conseils {
    #alaune {
        margin-bottom: -180px;
        padding-top: 40px;
        border-bottom: 230px solid #ffd1d8;
        .item--alaune {
            margin: 0;
            .imgWrapper {
                margin-bottom: -50px;
                @media (max-width: @screen-xs-max) {
                    margin-bottom: 0;
                }
            }
        }
    }
    #alaune--bienetre {
        margin-top: 30px;
        margin-bottom: 10px;
        border-bottom: 275px solid @brand-green;
        .item--conseil-bienetre {
            margin: 0;
            margin-bottom: -240px;
        }
    }
    #evidence {
        padding-top: 10px;
    }
    section.bienetre {
        margin-bottom: -195px;
        padding-bottom: 280px;
        background-image: url(../img/bg-bienetre.png);
        background-position: right bottom;
        background-repeat: no-repeat;
        @media (max-width: @screen-sm-max) {
            padding-bottom: 210px;
        }
        p {
            &.info {
                span {
                    color: @brand-green;
                }
            }
        }
        a {
            color: @brand-green;
        }
        .btn {
            .btn;
            .btn-green;
        }
        .publicity {
            border-color: @brand-green;
        }
    }
}

// Events
body.page-evenements {
    .view-content {
        margin-top: 20px;
    }
}