// Conseil
body.page-cart {
    section.view {
        padding-top: 25px;
        padding-bottom: 50px;
    }
    .form-group {
        &.form-item-edit-quantity-0 {
            margin-bottom: 0 !important;
        }
    }
    .btn-danger, .btn-info {
        .btn;
        .btn-secondary;
    }
}

body.page-checkout {
    #commerce-checkout-form-checkout, #commerce-checkout-form-review, #commerce-checkout-form-complete {
        padding-top: 25px;
        padding-bottom: 50px;
    }
    .checkout-help {
        margin-bottom: 15px;
    }
    .checkout-cancel, .checkout-back {
        .btn;
        margin-left: 5px;
        background-color: @brand-secondary !important;
        &:hover {
            background-color: darken(@brand-secondary, 15%) !important;
        }
    }
    .messages.error {
        padding: 5px 15px;
    }
    .component-type-commerce-price-formatted-amount {
        td {
            background-color: @brand-primary;
            color: #fff;
        }
    }
    .checkout_review {
        .form-group {
            margin-bottom: 0 !important;
            label {
                display: block;
            }
        }
    }
    tr.pane-title {
        td {
            background-color: @brand-secondary;
            color: #fff;
        }
    }
    .commerce-credit-card-expiration {
        .form-type-select, .commerce-month-year-divider {
            display: inline-block;
            margin-bottom: 0 !important;
        }
    }
}