// Footer
#social {
	padding-top: 40px;
	padding-bottom: 40px;
	font-size: 0;
	text-align: center;
	.addthis_toolbox {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			display: inline-block;
			vertical-align: middle;
			@media (max-width: @screen-xs-max) {
				.clearfix;
				display: block;
			}
		}
	}
	.shareLink, .addthis_toolbox a {
		display: inline-block;
		vertical-align: middle;
		padding: 0 15px;
		color: #1f1f1f;
		font-size: 0.75rem;
		font-weight: 400;
		line-height: 25px;
		text-decoration: none;
		@media (max-width: @screen-xs-max) {
			.clearfix;
			display: block;
		}
		.share, .fa, .csbuttons-count {
			float: left;
		}
		.fa, .csbuttons-count {
			float: left;
			height: 25px;
			color: #fff;
			font-size: 0.75rem;
			line-height: 25px;
			text-align: center;
		}
		.fa {
			margin: 0 0 0 10px;
			width: 25px;
			font-weight: 400;
			.transition(all .2s);
		}
		.csbuttons-count {
			width: 50px;
			font-weight: 700;
		}
		&.facebook {
			.fa {
				background-color: #3b5998;
			}
			.csbuttons-count {
				background-color: lighten(#3b5998, 10%);
			}
			&:hover {
				.fa {
					background-color: darken(#3b5998, 15%);
				}
			}
		}
		&.twitter {
			.fa {
				background-color: #55acee;
			}
			.csbuttons-count {
				background-color: lighten(#55acee, 10%);
			}
			&:hover {
				.fa {
					background-color: darken(#55acee, 15%);
				}
			}
		}
		&.google {
			.fa {
				background-color: #dd4b39;
			}
			.csbuttons-count {
				background-color: lighten(#dd4b39, 10%);
			}
			&:hover {
				.fa {
					background-color: darken(#dd4b39, 15%);
				}
			}
		}
		&.email {
			.fa {
				background-color: @brand-primary;
			}
			.csbuttons-count {
				background-color: lighten(@brand-primary, 10%);
			}
			&:hover {
				.fa {
					background-color: darken(@brand-primary, 15%);
				}
			}
		}
	}
	.shareLink + .shareLink, .addthis_toolbox li + li {
		border-left: 2px solid #e1e1e1;
		@media (max-width: @screen-xs-max) {
			margin-top: 5px;
			border: 0;
		}
	}
}

#footer {
	.topfooter {
		padding-bottom: 20px;
		background-color: @brand-primary;
		color: #fff;
		font-size: 1.13rem;
		line-height: 1.33em;
		h2, h3, h4, h5, h6 {
			margin: 40px 0 5px;
			color: @brand-secondary;
		}
		h3 {
			padding-left: 25px;
			background-image: url(../img/picto-tel.png);
			background-position: left top;
			background-repeat: no-repeat;
			background-size: 13px auto;
		}
		p, ul, ol {
			&:last-child {
				margin-bottom: 0;
			}
		}
		ul, ol {
			padding-left: 20px;
		}
	}
	.newsletter {
		padding: 0 10px 20px;
		background-color: @brand-secondary;
		overflow: auto;
		h3 {
			padding-left: 25px;
			color: #fff;
			background-image: url(../img/picto-email.png);
			background-position: left top 5px;
			background-size: 20px auto;
		}
		.form-group {
			margin: 8px 0 0 !important;
		}
		.form-type-checkbox {
			margin: 18px 0 0;
			label {
				color: #fff;
				font-size: 1rem;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
		button[type="submit"] {
			margin-top: 8px;
			&:hover {
				background-color: darken(@brand-secondary, 15%);
			}
		}
		.cta {
			border-top: 3px solid #fff;
			padding: 10px 0 0 0;
			margin: 10px 0 0 0;
			text-align: center;
		}
	}
	.facebook {
		margin-top: 20px;
	}
	.psy {
		margin-top: 60px;
		font-size: 0;
		svg {
			display: inline-block;
			vertical-align: bottom;
		}
		.baseline {
			display: inline-block;
			vertical-align: bottom;
			margin-left: 25px;
			color: #fff;
			font-size: 0.88rem;
			line-height: 1em;
			font-weight: 400;
			font-style: italic;
			@media (max-width: @screen-xs-max) {
				display: block;
				margin-top: 10px;
				margin-left: 0;
			}
		}
	}
	.bottomfooter {
		padding: 25px 0;
		text-align: center;
	}
	.footermenu {
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			font-size: 0;
			li {
				display: inline-block;
				vertical-align: middle;
				a {
					display: block;
					padding: 0;
					background-color: transparent;
					color: #232323;
					font-size: 0.69rem;
					font-weight: 400;
					line-height: 1.82em;
					text-decoration: none;
					.transition(all .2s);
				}
				&:hover {
					a {
						color: @brand-secondary;
					}
				}
				& + li {
					margin-left: 12px;
				}
			}
		}
	}
	.copyright {
		color: #232323;
		font-size: 0.69rem;
		font-weight: 400;
		line-height: 1.82em;
		a {
			color: #232323;
			text-decoration: none;
			.transition(all .2s);
			&:hover {
				color: @brand-secondary;
			}
		}
	}
}