// Annuaire
body.page-psy {
    #main {
        > h2 {
            margin-top: 0;
            margin-bottom: 20px;
        }
        > p {
            margin: 0 0 10px;
            line-height: 1.5em;
            &:last-child {
                margin-bottom: 0;
            }
            &:last-of-type {
                margin-bottom: 20px;
            }
        }
    }
    .psy-cta {
      display: block;
      height: 84px;
      background-image: url(../img/psy-cta-bg.png);
      background-repeat: no-repeat;
      background-position: top left;
      padding: 10px 10px 10px 500px;
      color: #fff;
      &:hover {
        text-decoration: none;
      }
      @media (max-width: @screen-md) {
        background-color: @brand-primary;
        background-image: none;
        height: auto;
        padding: 10px;
      }
    }
     #filters {
        position: relative;
        z-index: 1;
        background-color: #ffd1d8;

        .form-wrapper, .more {
            padding: 25px 20px;
        }

        h2 {
            margin: 0 0 15px;
            color: @brand-primary;
        }

        .toggleForm {
            display: block;
            position: absolute;
            top: 25px;
            right: 20px;
            text-decoration: none;
            &:before {
                content: '\f0c9';
                display: block;
                height: 24px;
                width: 24px;
                color: @brand-primary;
                font-family: 'FontAwesome';
                font-size: 24px;
                line-height: 24px;
                text-align: center;
                .transition(all .2s);
            }
            &.open {
                &:before {
                    content: '\f00d';
                }
            }
        }

        .forms-wrapper {
            @media (min-width: @screen-sm-min) {
                display: block !important;
            }
            @media (max-width: @screen-xs-max) {
                display: none;
            }
        }

        .form-group {
            margin: 0 0 5px !important;
            label {
                display: block;
                margin: 0;
                font-size: 1rem;
                font-weight: 400;
            }
            &.checkbox {
                margin-top: 5px !important;
                padding: 0 10px;
            }
            .checkbox-inline {
                display: inline-block;
                padding-left: 5px;
            }
        }

        .form-control {
            padding: 0 10px;
            border: 0;
        }
        .form-control, output {
            font-size: 1rem;
        }
        ::-webkit-input-placeholder {
           color: #858585;
        }

        :-moz-placeholder {
           color: #858585;
        }

        ::-moz-placeholder {
           color: #858585;
        }

        :-ms-input-placeholder {
           color: #858585;
        }


        input[type="checkbox"] {
            position: relative;
            margin: 0 5px 0 0;
            height: 14px;
            width: 14px;
            background-color: #fff;
            outline: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            &:checked {
                &:before {
                    content: '\f00c';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 14px;
                    color: @brand-primary;
                    font-family: 'FontAwesome';
                    font-size: 0.63rem;
                    font-weight: 400;
                    line-height: 14px;
                    text-align: center;
                }
            }
        }

        button[type="submit"] {
            display: none;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .more {
            background-color: @brand-primary;

            h3 {
                position: relative;
                margin: 0 0 20px;
                padding-left: 30px;
                color: #fff;
                font-size: 1rem;
                line-height: 1.13em;
                &:before {
                    content: '?';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    background-color: #fff;
                    border-radius: 100%;
                    color: @brand-primary;
                    font-size: 0.88rem;
                    font-weight: 900;
                    line-height: 20px;
                    text-align: center;
                }
            }

            .btn {
                display: block;
                position: relative;
                padding-left: 25px;
                padding-right: 25px;
                color: @brand-primary;
                font-size: 0.69rem;
                &:after {
                    content: '\f105';
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    margin-top: -5px;
                    font-family: 'FontAwesome';
                    font-size: 10px;
                    line-height: 1em;
                }
            }
        }

        .bootstrap-select {
            display: block;
            position: relative;
            padding: 0;
            height: auto;
            background-color: transparent;
            border-radius: 0;
            .dropdown-toggle {
                display: block;
                padding: 7px 10px;
                height: 34px;
                background-color: #fff;
                color: #858585;
                font-size: 1rem;
                letter-spacing: 0;
                line-height: normal;
                text-align: left;
                text-transform: none;
                outline: 0 !important;
                text-overflow: ellipsis;
                white-space: nowrap;
                .filter-option {
                    display: block;
                    float: none !important;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 90%;
                }
            }
            .bs-searchbox {
                padding: 0;
                .form-control {
                    margin: 15px;
                    width: calc(~"100% - 30px");
                    border: 1px solid #d7d7d7;
                    -webkit-box-shadow: none !important;
                    -moz-box-shadow: none !important;
                    box-shadow: none !important;
                }
            }
            > .dropdown-menu {
                margin: 0;
                padding: 0;
                @media (max-width: @screen-xs-max) {
                    max-width: 100%;
                    overflow-x: hidden;
                    li {
                        a {
                            padding-top: 10px;
                            padding-bottom: 10px;
                            line-height: 1em;
                            white-space: normal;
                        }
                    }
                }
            }
            .dropdown-menu.inner {
                max-height: 150px !important;
            }
        }
     }
     #results {
        position: relative;
        z-index: 0;
        .form-wrapper {
            position: relative;
            padding: 25px 0 25px 20px;
            background-color: @brand-primary;
            @media (max-width: @screen-xs-max) {
                margin-top: 15px;
                padding-left: 15px;
                padding-right: 15px;
            }
            &:before, &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: 9999px;
                background-color: @brand-primary;
            }
            &:before {
                right: 100%;
            }
            &:after {
                left: 100%;
            }
            .form-group {
                display: inline-block;
                vertical-align: middle;
                margin: 0 !important;
                @media (max-width: @screen-sm-max) {
                    .clearfix;
                    display: block;
                }
                label {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 10px 0 0;
                    color: #fff;
                    @media (max-width: @screen-sm-max) {
                        display: block;
                        float: left;
                        margin: 0;
                        padding: 0 10px 0 0;
                        width: 30%;
                    }
                    @media (max-width: @screen-xs-max) {
                        padding: 0 0 10px 0;
                        width: 100%;
                    }
                }
                .form-control {
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0 10px;
                    width: auto;
                    max-width: 150px;
                    border: 0;
                    @media (max-width: @screen-sm-max) {
                        display: block;
                        float: left;
                        width: 70%;
                        max-width: none;
                    }
                    @media (max-width: @screen-xs-max) {
                        width: 100%;
                    }
                }
                .form-control, output {
                    font-size: 1rem;
                }
                & + .form-group {
                    margin-left: 10px !important;
                    @media (max-width: @screen-sm-max) {
                        margin-left: 0 !important;
                        margin-top: 10px !important;
                    }
                }
            }
        }
        .header {
            position: relative;
            padding: 25px 0 15px 20px;
            border-bottom: 1px solid #f0f0f0;
            @media (max-width: @screen-xs-max) {
                padding-left: 15px;
                padding-right: 15px;
            }
            &:before, &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: 9999px;
                border-bottom: 1px solid #f0f0f0;
            }
            &:before {
                right: 100%;
            }
            &:after {
                left: 100%;
            }
            .wrapper {
                .clearfix;
                > p {
                    float: left;
                    margin: 0;
                    strong {
                        color: @brand-secondary;
                    }
                    @media (max-width: @screen-xs-max) {
                        float: none;
                    }
                }
                > .btn {
                    float: right;
                    @media (max-width: @screen-xs-max) {
                        float: none;
                        margin-top: 5px;
                    }
                }
            }
            .gmap {
                display: none;
                clear: both;
                padding-top: 15px;
            }
        }
        .results {
            margin-top: 25px;
            padding-left: 55px;
            @media (max-width: @screen-xs-max) {
                padding-left: 0;
            }
            .pagination {
                float: right;
                margin-top: 15px;
            }
        }
     }
}


// Page Annuaire
body.page-annuaire {
    .annuaire-wrapper {
        padding-top: 20px;
        padding-bottom: 60px;
        h2 {
            margin: 0;
            color: @text-color;
            font-size: 1rem;
            line-height: 1.88em;
        }
        p {
            margin: 0;
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        .result {
            & + .result {
                margin-top: 20px;
            }
        }
    }
}
