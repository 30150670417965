body:not(.cke_editable) {
  padding-top: 130px;
  @media (max-width: @screen-sm-max) {
    padding-top: 70px;
  }
}
body.page-psy,
body.node-type-consultation {
  .global-wrapper {
    overflow-x: hidden;
  }
}
.container {
  &.md-width {
    max-width: 970px;
  }
}
.no-gap {
  padding-left: 0;
  padding-right: 0;
}
.no-marge {
  margin-left: 0;
  margin-right: 0;
}
.xs-gap {
  padding-left: 3px;
  padding-right: 3px;
}
.xs-marge {
  margin-left: -3px;
  margin-right: -3px;
}
.sm-gap {
  padding-left: 8px;
  padding-right: 8px;
}
.sm-marge {
  margin-left: -8px;
  margin-right: -8px;
}
.bg-gray {
  background-color: #f7f6f4;
}

.clear-row {
  clear: both;
}

.mt-30 {
  margin-top: 30px;
}

.v-align {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.no-gap {
  padding-left: 0;
  padding-right: 0;
}
.bg {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// Animate
.animated {
  .animated;
}
.os-animation {
  opacity: 0;
  @media (max-width: @screen-xs-max) {
    -webkit-animation-delay: 0s !important;
    -moz-animation-delay: 0s !important;
    -ms-animation-delay: 0s !important;
    -o-animation-delay: 0s !important;
    animation-delay: 0s !important;
  }
}

.item-delay {
  @media (min-width: @screen-sm-min) {
    &:nth-child(1) {
      -webkit-animation-delay: 0s;
      -moz-animation-delay: 0s;
      animation-delay: 0s;
    }
    &:nth-child(2) {
      -webkit-animation-delay: 0.25s;
      -moz-animation-delay: 0.25s;
      animation-delay: 0.25s;
    }
    &:nth-child(3) {
      -webkit-animation-delay: 0.5s;
      -moz-animation-delay: 0.5s;
      animation-delay: 0.5s;
    }
    &:nth-child(4) {
      -webkit-animation-delay: 0.75s;
      -moz-animation-delay: 0.75s;
      animation-delay: 0.75s;
    }
  }
}

// Content Body
.content-body {
  position: relative;

  // Colonnes
  & > .row {
    margin-bottom: 30px;
  }

  p[style*="text-align: center;"] {
    img {
      display: inline-block;
    }
  }
}

// Type
h1,
.h1 {
  margin-top: 30px;
  margin-bottom: 10px;
}
h2,
.h2 {
  margin-top: 35px;
  margin-bottom: 10px;
  color: @brand-secondary;
}
body.front {
  h2,
  .h2 {
    margin-top: 35px;
    margin-bottom: 20px;
    color: @brand-primary;
    font-size: 1.5rem;
  }
}
.catLabel {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 10px;
  padding: 5px 10px;
  background-color: #f7f6f4;
  border-radius: 2px;
  color: #959595;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25em;
}

// Lead
p.lead {
  font-size: 1.33em;
  line-height: 1.5em;
  color: @brand-primary;
  font-weight: 300;
  margin-bottom: @line-height-computed;
}

// Evidence
p.evidence,
div.evidence {
  color: #fff;
  background-color: @brand-primary;
  padding: @line-height-computed*2;
  margin-bottom: @line-height-computed;
  @media (max-width: @screen-sm-max) {
    padding: 30px;
  }
  @media (max-width: @screen-xs-max) {
    padding: 15px;
  }

  a {
    color: #fff;
  }

  a.btn {
    background-color: #fff;
    color: @brand-primary;

    &:hover {
      background-color: darken(#fff, 10%);
    }
  }
}

// Img
img {
  max-width: 100%;
  height: auto;

  &[style*="left"] {
    margin-right: @line-height-computed;
    margin-bottom: @line-height-computed;
  }

  &[style*="right"] {
    margin-left: @line-height-computed;
    margin-bottom: @line-height-computed;
  }
}

// Table
table:not(.custom-table) {
  margin-bottom: 30px !important;
  width: 100%;
  border: 1px solid #d7d7d7;
  th {
    border: 0 !important;
    background-color: #f2f1f1;
    color: #210e11;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.88em;
  }

  td {
    background-color: #fff;
    border-top: 1px solid #d7d7d7 !important;
  }

  td,
  th {
    padding: 20px !important;
  }
}
fieldset {
  padding: 15px 0;
  legend {
    margin-bottom: 15px !important;
    border: 0;
    color: @brand-secondary;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.25em;
  }
}
@media (max-width: @screen-xs-max) {
  .table-responsive > .table {
    margin-bottom: 0 !important;
  }
}

// Iframe responsive
.iframe-embed {
  display: block;
  position: relative;
  margin-bottom: @line-height-computed;
  padding: 0;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

// Boutons
.btn {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 7px 40px;
  border: 0;
  border-radius: 4px;
  color: #fff;
  font-size: 0.88rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.43em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  outline: 0;
  white-space: normal;
  .transition(all 0.2s);
  &:hover,
  &:focus {
    outline: 0;
    color: #fff;
  }
  &.btn-primary,
  &.btn-default,
  &.btn1 {
    background-color: @brand-primary;
    &:hover {
      background-color: @brand-secondary;
    }
  }
  &.btn-secondary,
  &.btn2 {
    background-color: @brand-secondary;
    &:hover {
      background-color: darken(@brand-secondary, 15%);
    }
  }
  &.btn-white {
    background-color: #fff;
    color: @brand-primary;
    &:hover {
      background-color: @brand-primary;
      color: #fff;
    }
  }
  &.btn-green {
    background-color: #fff;
    color: @brand-green;
    &:hover {
      background-color: @brand-green;
      color: #fff;
    }
  }
  &.btn-in-text {
    padding: 5px 10px;
    margin-right: 5px;
    margin-left: 5px;
  }
  &.free {
    background-color: @brand-abo1;
    &:hover {
      background-color: darken(@brand-abo1, 15%);
    }
  }
  &.premium {
    background-color: @brand-abo2;
    &:hover {
      background-color: darken(@brand-abo2, 15%);
    }
  }
  &.booster {
    background-color: @brand-abo3;
    &:hover {
      background-color: darken(@brand-abo3, 15%);
    }
  }
  &.max {
    background-color: @brand-abo4;
    &:hover {
      background-color: darken(@brand-abo4, 15%);
    }
  }
  &.btn-backToList {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.item {
  display: block;
  margin: 15px 0;
  text-decoration: none;
  p {
    &.info {
      color: @brand-primary;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.17em;
      span {
        color: @brand-secondary;
      }
    }
  }
  &.item--conseil {
    .imgWrapper {
      position: relative;
      span {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0 10px;
        background-color: @brand-secondary;
        color: #fff;
        font-size: 0.75rem;
        font-weight: 700;
      }
    }
    .content {
      padding: 25px 0;
      min-height: 220px;
      @media (max-width: @screen-sm-max) {
        min-height: 265px;
      }
      @media (max-width: @screen-xs-max) {
        min-height: 0;
      }
    }
    h3 {
      margin: 5px 0;
      font-size: 1rem;
      line-height: 1.13em;
      a {
        margin: 0;
        color: @brand-primary;
        font-size: 1rem;
        line-height: 1.13em;
        text-decoration: none;
      }
    }
    p {
      margin: 0;
    }
    a {
      display: block;
      margin-top: 5px;
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 2.5em;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.item--events {
    .item--conseil;
    margin: 3px 0;
    .wrapper {
      padding: 15px 12px 0;
      background-color: #fff;
    }
    .content {
      padding: 15px 0;
      min-height: 285px;
    }
    p {
      line-height: 1.5em;
      &.info {
        line-height: 1.17em;
        span {
          display: block;
          margin-top: 5px;
        }
      }
    }
    a {
      margin-top: 15px;
    }
    &.item-large {
      margin: 15px 0;
      border-bottom: 1px solid #f0f0f0;
      a {
        margin-top: 5px;
      }
      .content {
        padding: 15px 0;
        min-height: 250px;
      }
    }
  }
  &.item--interview {
    .clearfix;
    .imgWrapper,
    .content {
      float: left;
      width: 50%;
      @media (max-width: @screen-xs-max) {
        float: none;
        width: 100%;
      }
    }
    .imgWrapper {
      img {
        border: 8px solid #fff;
      }
    }
    .content {
      padding: 0 30px 20px;
      @media (max-width: @screen-xs-max) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    h2 {
      margin-top: 5px;
      margin-bottom: 15px;
      color: @brand-primary;
      font-size: 1.5rem;
      span {
        display: block;
        color: @brand-secondary;
      }
    }
    p:not(.info) {
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.item--alaune {
    .item--interview;
    .imgWrapper {
      padding-right: 15px;
      @media (max-width: @screen-xs-max) {
        padding-right: 0;
      }
    }
    .content {
      padding-left: 15px;
    }
    p {
      &.info {
        margin-top: 25px;
        margin-bottom: 15px;
      }
    }
  }
  &.item--conseilLarge {
    figure {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        z-index: 5;
        bottom: 0;
        left: 0;
        height: 60%;
        width: 100%;
        opacity: 0.7;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
      }
      img {
        position: relative;
        z-index: 0;
      }
      figcaption {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        > div {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 30px 40px;
          width: 100%;
          @media (max-width: @screen-xs-max) {
            padding: 15px 20px;
          }
        }
      }
      h2 {
        margin: 0 0 10px;
        color: #fff;
        font-size: 1.5rem;
      }
      .info {
        &:not(span) {
          color: #fff;
        }
      }
    }
    .content {
      padding: 30px 40px;
      border: 1px solid #f0f0f0;
      border-top: 0;
      @media (max-width: @screen-xs-max) {
        padding: 15px 20px;
      }
      p {
        color: #58585a;
      }
    }
  }
  &.item--conseilHor {
    .item--conseil;
    .wrapper {
      .clearfix;
      padding-bottom: 30px;
      border-bottom: 1px solid #f0f0f0;
    }
    .imgWrapper {
      float: left;
      width: 25%;
      @media (max-width: @screen-xs-max) {
        width: 100%;
      }
    }
    .content {
      float: left;
      padding: 0 20px;
      min-height: 0;
      width: 75%;
      @media (max-width: @screen-xs-max) {
        padding: 10px 15px 0;
        width: 100%;
      }
    }
    h3 {
      margin-bottom: 10px;
    }
    .info {
      margin-bottom: 15px;
    }
    &.publicity {
      border: 2px solid @brand-secondary;
    }
  }
  &.item--psy {
    .imgWrapper {
      img {
        margin: 0 auto 20px;
        max-width: 135px;
        background-color: #fff;
        border: 2px solid #f7f6f4;
        border-radius: 50%;
      }
    }
    .content {
      text-align: center;
    }
    h3 {
      margin: 0;
      font-size: 1rem;
      line-height: 1.13em;
    }
    p {
      margin: 0;
      color: @brand-primary;
      font-size: 1rem;
      line-height: 1.5em;
      &.ville {
        font-size: 0.75rem;
        font-weight: 700;
      }
    }
    a {
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 2em;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.item--conseil-bienetre {
    position: relative;
    padding: 0 15px;
    height: 420px;
    width: 100%;
    border: 8px solid #fff;
    .content {
      z-index: 5;
      width: 100%;
      text-align: center;
      h2 {
        margin: 0 0 10px;
        color: #fff;
        font-size: 3rem;
        line-height: 1em;
        @media (max-width: @screen-xs-max) {
          font-size: 1.88rem;
        }
      }
      p {
        margin: 0 0 25px;
        color: #fff;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1em;
      }
    }
  }
  &.item--consultation {
    h2 {
      margin: 0 0 5px;
      font-size: 1.5rem;
      text-align: center;
    }
    .text {
      color: #333333;
      text-align: center;
    }
    .imgWrapper {
      position: relative;
      img {
        margin: 15px auto -35px;
        width: 300px;
        border-radius: 50%;
      }
      figcaption {
        display: block;
        position: absolute;
        top: 30px;
        right: 0;
        height: 140px;
        width: 140px;
        background-color: @brand-secondary;
        border-radius: 50%;
        color: #fff;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.25em;
        text-align: center;
        @media (max-width: @screen-xs-max) {
          top: 0;
        }
        span {
          display: block;
          padding: 20px;
          width: 100%;
        }
      }
    }
    .content {
      padding: 60px 40px 35px;
      min-height: 390px;
      background-color: @brand-primary;
      border-radius: 8px;
      color: #fff;
    }
    .form-group {
      .clearfix;
      margin-bottom: 5px !important;
      label {
        float: left;
        margin: 0;
        padding-right: 10px;
        width: 115px;
        color: #fff;
        font-size: 1rem;
        font-weight: 400;
        text-align: right;
        @media (max-width: @screen-sm-max) {
          float: none;
          padding-right: 0;
          width: 100%;
          text-align: center;
        }
      }
      .form-control {
        float: left;
        padding: 0 10px;
        width: 180px;
        @media (max-width: @screen-sm-max) {
          float: none;
          width: 100%;
        }
      }
      .form-control,
      output {
        font-size: 1rem;
      }
    }
    button[type="submit"] {
      margin-top: 15px;
      margin-left: 115px;
      width: 180px;
      @media (max-width: @screen-sm-max) {
        margin-left: 0;
        width: 100%;
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        padding-left: 35px;
        background-position: left top 5px;
        background-repeat: no-repeat;
        background-size: 25px auto;
        p {
          margin: 0 0 15px;
        }
        .btn {
          padding-left: 30px;
          padding-right: 30px;
        }
        &.email {
          background-image: url(../img/picto-email.png);
        }
        &.conf {
          background-image: url(../img/picto-conf.png);
        }
        & + li {
          margin-top: 15px;
        }
      }
    }
  }
  &.item--alacarte {
    margin: 0;
    padding: 25px 35px;
    min-height: 340px;
    background-color: transparent;
    border: 1px solid #d7d7d7;
    @media (max-width: @screen-sm-max) {
      padding: 25px 20px;
      min-height: 365px;
    }
    &:nth-child(n + 3) {
      margin-top: -1px;
      @media (max-width: @screen-xs-max) {
        margin: 0;
      }
    }
    &:nth-child(even) {
      margin-left: -1px;
      @media (max-width: @screen-xs-max) {
        margin: 0;
      }
    }
    @media (max-width: @screen-xs-max) {
      & + .item--alacarte {
        margin-top: -1px;
      }
    }
    .puces {
      .clearfix;
      .puce {
        display: block;
        float: left;
        height: 90px;
        width: 90px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 55px auto;
        border: 2px solid #dca200;
        border-radius: 50%;
        & + .puce {
          margin-left: 10px;
        }
        &.puce--price {
          position: relative;
          background-color: #dca200;
          color: #fff;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1em;
          text-align: center;
          text-transform: uppercase;
          span {
            .v-align;
            display: block;
            width: 100%;
            small {
              display: block;
              margin-top: -5px;
              font-size: 0.75rem;
              text-transform: none;
            }
          }
        }
      }
    }
    h3 {
      margin: 15px 0 5px;
      font-size: 1.5rem;
    }
    p {
      margin: 0 0 20px;
      color: #58585a;
      line-height: 1.5em;
    }
    &.form-annonce {
      .puces {
        .puce--icon {
          background-image: url(../img/form-annonce.png);
        }
      }
    }
    &.form-visibilite {
      .puces {
        .puce--icon {
          background-image: url(../img/form-visibilite.png);
        }
      }
    }
    &.form-annonce-alaune {
      .puces {
        .puce--icon {
          background-image: url(../img/form-annonce-alaune.png);
        }
      }
    }
    &.form-pub-news {
      .puces {
        .puce--icon {
          background-image: url(../img/form-pub-news.png);
        }
      }
    }
    &.form-news {
      .puces {
        .puce--icon {
          background-image: url(../img/form-news.png);
        }
      }
    }
    &.form-pub-hp {
      .puces {
        .puce--icon {
          background-image: url(../img/form-pub-hp.png);
        }
      }
    }
    &.form-pub-bienetre {
      .puces {
        .puce--icon {
          background-image: url(../img/form-pub-bienetre.png);
        }
      }
    }
  }
}

#filters {
  &.bordered {
    border-bottom: 1px solid #f0f0f0;
  }
  .cat {
    .clearfix;
    margin: 10px 0 0;
    padding: 0 0 10px;
    > a {
      display: block;
      float: left;
      margin: 0 5px 5px 0;
      padding: 5px 10px;
      padding-left: 35px;
      background-color: #f2f1f1;
      background-position: left 10px center;
      background-repeat: no-repeat;
      background-size: 16px auto;
      color: #210e11;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.88em;
      text-decoration: none;
      .transition(all 0.35s);
      @media (max-width: @screen-xs-max) {
        float: none;
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.cat_allArticles {
        background-image: url(../img/picto-article.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-article--pink.png);
        }
      }
      &.cat_allEvents {
        background-image: url(../img/picto-evenement.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-evenement--pink.png);
        }
      }
      &.cat_1 {
        background-image: url(../img/picto-stage.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-stage--pink.png);
        }
      }
      &.cat_2 {
        background-image: url(../img/picto-formation.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-formation--pink.png);
        }
      }
      &.cat_3 {
        background-image: url(../img/picto-conference.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-conference--pink.png);
        }
      }
      &.cat_4 {
        background-image: url(../img/picto-atelier.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-atelier--pink.png);
        }
      }
      &.cat_11 {
        background-image: url(../img/picto-ado.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-ado--pink.png);
        }
      }
      &.cat_12 {
        background-image: url(../img/picto-couple.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-couple--pink.png);
        }
      }
      &.cat_13 {
        background-image: url(../img/picto-famille.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-famille--pink.png);
        }
      }
      &.cat_14 {
        background-image: url(../img/picto-psycho.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-psycho--pink.png);
        }
      }
      &.cat_15 {
        background-image: url(../img/picto-senior.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-senior--pink.png);
        }
      }
      &.cat_16 {
        background-image: url(../img/picto-travail.png);
        &:hover,
        &.active {
          background-image: url(../img/picto-travail--pink.png);
        }
      }
      &.cat_17 {
        background-color: @brand-green;
        background-image: url(../img/picto-bienetre--white.png);
        color: #fff;
        &:hover,
        &.active {
          background-image: url(../img/picto-bienetre--green.png);
          color: @brand-green;
        }
      }
      &:hover,
      &.active {
        background-color: transparent;
        color: @brand-secondary;
      }
    }
  }
  .subcat {
    .clearfix;
    margin: 0;
    padding: 0 0 15px;
    > a {
      display: block;
      float: left;
      margin: 0;
      margin-right: 25px;
      color: @brand-primary;
      font-size: 0.88rem;
      font-weight: 400;
      line-height: 2.14em;
      text-decoration: none;
      .transition(all 0.35s);
      @media (max-width: @screen-xs-max) {
        float: none;
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover,
      &.active {
        color: @brand-secondary;
        font-weight: 700;
      }
      &.prnt_cat_17 {
        &:hover,
        &.active {
          color: @brand-green;
        }
      }
    }
  }
}
.event-cta {
  float: right;
  padding: 20px;
  border: 1px solid @brand-primary;
  margin-left: 50px;
  @media (max-width: @screen-xs-max) {
    margin-left: 0;
  }
}
.pagination {
  .clearfix;
  display: block;
  float: left;
  margin: 50px 0 10px;
  padding: 0;
  border-radius: 0;
  li {
    display: block;
    float: left;
    a,
    span {
      display: block;
      position: relative;
      float: left;
      margin: 0 !important;
      padding: 0 8px;
      background-color: transparent !important;
      border-radius: 0 !important;
      border: 0;
      color: @brand-primary !important;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1em;
      text-decoration: underline;
    }
    &:after {
      content: "|";
      display: block;
      float: left;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1em;
    }
    &:first-child {
      a,
      span {
        padding-left: 0;
      }
    }
    &.active,
    &:hover {
      a,
      span {
        font-weight: 700;
        text-decoration: none;
      }
    }
  }
}

.psy-block-info {
  clear: both;
  margin-bottom: 5px;
  background-color: #fff;
  border: 8px solid #ececec;
  .avatar {
    float: left;
    padding: 15px 20px;
    width: 15%;
    @media (max-width: @screen-xs-max) {
      float: none;
      width: 100%;
    }
    img {
      width: 96px;
      background-color: #fff;
      border: 1px solid #d7d7d7;
      border-radius: 50%;
    }
    .dispo {
      display: block;
      margin: 5px 0 0;
      padding: 12px 10px;
      border-radius: 4px;
      color: #fff;
      font-size: 0.69rem;
      font-weight: 700;
      line-height: 1em;
      text-align: center;
      &.t_instant,
      &.t_48h {
        padding-top: 36px;
        background-position: center top 10px;
        background-repeat: no-repeat;
        background-size: 24px auto;
      }
      &.t_instant {
        background-color: #2fa500;
        background-image: url(../img/picto-instant.png);
      }
      &.t_48h {
        background-color: #ee6200;
        background-image: url(../img/picto-48h.png);
      }
      &.t_mois {
        background-color: #e2b32d;
      }
      &.t_indisponible {
        background-color: #b6b6b6;
      }
    }
  }
  .infos {
    float: left;
    width: 85%;
    @media (max-width: @screen-xs-max) {
      float: none;
      width: 100%;
    }
    .coordonnees {
      padding: 25px 0;
      border-bottom: 1px solid #d7d7d7;
      > div {
        &:nth-child(1) {
          padding: 0;
          @media (max-width: @screen-xs-max) {
            padding: 0 15px;
          }
        }
        &:nth-child(2) {
          padding: 0 25px;
          @media (max-width: @screen-xs-max) {
            margin-top: 15px;
            padding: 0 15px;
          }
        }
      }
      h2 {
        display: inline-block;
        vertical-align: top;
        margin: 0 0 10px;
        color: @brand-primary;
        font-size: 1.5rem;
        line-height: 1em;
      }
      .langues {
        .clearfix;
        display: inline-block;
        vertical-align: top;
        margin: 0 0 0 5px;
        padding: 0;
        list-style: none;
        li {
          display: block;
          float: left;
          height: 20px;
          width: 20px;
          border: 1px solid #d7d7d7;
          color: #b7b7b7;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 18px;
          text-align: center;
          text-transform: uppercase;
          & + li {
            margin-left: 5px;
          }
        }
      }
      p {
        margin: 0;
        color: @brand-secondary;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.29em;
      }
      .btn {
        display: block;
        float: right;
        padding: 5px 15px;
        font-size: 0.75rem;
        width: 100%;
        max-width: 270px;
        @media (max-width: @screen-xs-max) {
          float: none;
        }
        & + .btn {
          margin-top: 5px;
        }
      }
    }
    .types {
      padding: 20px 0;
      > div {
        &:nth-child(1) {
          padding: 0;
          @media (max-width: @screen-xs-max) {
            padding: 0 15px;
          }
        }
        &:nth-child(2) {
          padding: 0 25px;
          @media (max-width: @screen-xs-max) {
            margin-top: 15px;
            padding: 0 15px;
          }
        }
      }
      p {
        margin: 0;
        color: @brand-primary;
        font-size: 1rem;
        line-height: 1.5em;
      }
      .btn {
        display: block;
        float: right;
        margin: 15px 0 0;
        padding: 5px 30px;
        font-size: 0.75rem;
        @media (max-width: @screen-xs-max) {
          float: none;
        }
      }
    }
  }
  &.psy-annuaire {
    margin-bottom: 10px;
    .avatar {
      width: 22%;
      @media (max-width: @screen-sm-max) {
        width: 100%;
        img {
          margin: 0 auto;
        }
      }
    }
    .infos {
      width: 78%;
      @media (max-width: @screen-sm-max) {
        width: 100%;
      }
      .coordonnees {
        .btn {
          margin-top: 20px;
          padding-left: 30px;
          padding-right: 30px;
          width: auto;
        }
        @media (max-width: @screen-sm-max) {
          > div:nth-child(2) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
      @media (max-width: @screen-sm-max) {
        .coordonnees,
        .types {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
    &.member-11,
    &.member-9,
    &.member-8 {
      .coordonnees {
        p {
          color: @brand-abo4;
        }
      }
    }
    &.member-11 {
      border-color: @brand-abo5;
    }
    &.member-9 {
      border-color: @brand-abo4;
    }
    &.member-8 {
      border-color: @brand-abo3;
    }
    &.member-7 {
      border-color: @brand-abo2;
    }
  }
}

.content-details {
  margin-top: 40px;
  margin-bottom: 40px;
  h1 {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .info {
    margin: 0;
    padding: 20px 0 0;
    color: @brand-primary;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.17em;
    span {
      color: @brand-secondary;
    }
    &.info-event {
      line-height: 1.17em;
      span {
        display: block;
        margin-top: 5px;
      }
    }
  }
  .objectifs {
    clear: both;
  }
  img {
    float: left;
    margin: 0 35px 25px 0;
    width: 300px;
    /* @media (max-width: @screen-sm-max) {
		            width: 360px;
		        } */
    @media (max-width: @screen-xs-max) {
      float: none;
      margin: 0;
      width: 450px;
    }
    ~ ul {
      list-style-position: inside;
    }
  }
}
.content-interview {
  margin-top: 40px;
  margin-bottom: 40px;
}

.compsy {
  margin-top: 30px;
  max-width: 200px;
  border: 1px solid #d3d3d3;
  h4 {
    margin: 10px 15px;
    color: #1d6085;
    text-align: center;
    text-transform: uppercase;
  }
}

.optGroup {
  font-weight: 700;
}
.optChild {
  font-style: italic;
}

.carousel {
  clear: both;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  .item {
    margin: 0;
  }
  img {
    float: none;
    margin: 0;
    width: 100%;
  }
  &#carousel-event {
    float: left;
    margin: 0 30px 30px 0;
    width: 50%;
    .owl-prev,
    .owl-next {
      position: absolute;
      top: 50%;
      font-size: 48px;
      line-height: 1em;
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }
    .owl-prev {
      left: 15px;
    }
    .owl-next {
      right: 15px;
    }
    .owl-dots {
      position: absolute;
      bottom: 15px;
      left: 0;
      width: 100%;
      font-size: 0;
      text-align: center;
      .owl-dot {
        display: inline-block;
        vertical-align: middle;
        margin: 5px;
        height: 8px;
        width: 8px;
        border: 2px solid @brand-primary;
        border-radius: 50%;
        .transition(all 0.2s);
        &.active,
        &:hover {
          background-color: @brand-primary;
        }
      }
    }
    @media (max-width: @screen-xs-max) {
      float: none;
      margin: 0 0 30px;
      width: 100%;
    }
    & ~ ul {
      list-style-position: inside;
    }
  }
}

body.role-PSY---Free {
  .field-name-field-user-adresse {
    > #field-user-adresse-add-more-wrapper {
      > .form-item {
        > .description,
        > .description + .clearfix {
          display: none;
        }
      }
    }
  }
}

#disqus_thread {
  #onboard {
    display: none !important;
  }
}

.cta-event {
  clear: both;
  padding-top: 40px;
  img {
    margin: 0;
    height: auto;
    width: 100%;
  }
}
