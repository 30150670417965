.form-control {
  height: 34px;
  width: 100%;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.form-control,
output {
  display: block;
  color: @brand-primary;
  font-size: 1.13rem;
  line-height: normal;
}
::-webkit-input-placeholder {
  color: @brand-primary;
}

:-moz-placeholder {
  color: @brand-primary;
}

::-moz-placeholder {
  color: @brand-primary;
}

:-ms-input-placeholder {
  color: @brand-primary;
}
label {
  margin-bottom: 10px;
  color: @brand-primary;
  font-size: 1.13rem;
  font-weight: 700;
}
.form-group {
  margin-bottom: 15px !important;
}

.checkbox label,
.radio label {
  padding-left: 0;
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  position: relative;
  margin: 0 5px 0 0;
}

form {
  .form-required {
    color: @brand-danger;
  }
  .form-border {
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #d7d7d7;
  }
  .form-inline {
    .control-label {
      vertical-align: baseline;
    }
    .form-radios,
    .form-checkboxes {
      min-height: 34px;
    }
    .form-type-radio,
    .form-type-checkbox {
      display: inline-flex;
      margin-right: 5px;
    }
  }
  #edit-payment,
  #edit-subscription {
    .form-text,
    .form-select {
      .form-control;
    }
  }
  #edit-subscription {
    .form-border;
    fieldset {
      padding: 0;
    }
  }

  &#user-profile-form {
    #edit-mimemail {
      display: none;
    }
    .group-user-infos,
    .field-name-field-user-facturation {
      .form-border;
    }
    .field-type-addressfield {
      .tabledrag-toggle-weight-wrapper,
      .field-multiple-drag {
        display: none;
      }
      td {
        border: 1px solid #d7d7d7;
      }
    }
    .bootstrap-select {
      display: block !important;
      position: relative;
      .dropdown-toggle {
        .form-control;
        background-color: #fff !important;
        color: @brand-primary !important;
        font-size: 1.13rem;
        letter-spacing: 0;
        line-height: normal;
        text-align: left;
        text-transform: none;
        outline: 0 !important;
        overflow: hidden;
        span {
          display: block;
          line-height: 32px;
        }
      }
      .dropdown-menu {
        max-width: 100%;
      }
    }
  }
}

body.page-consultation {
  label {
    margin-bottom: 0;
  }
  .container-inline-date {
    > .form-item {
      display: block;
      margin: 0 !important;
      width: 100%;
    }
    .date-padding {
      float: none;
    }
    .form-item {
      .form-item {
        float: none;
      }
    }
  }
}
