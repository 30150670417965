body.node-type-consultation {
    .consultations {
        position: relative;
        z-index: 1;
    }
    .consul-avantages {
        position: relative;
        margin: -60px auto 0;
        padding: 115px 0 65px;
        max-width: 780px;
        background-color: #f8f8f8;
        &:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            width: 9999px;
            background-color: #f8f8f8;
        }
        &:before {
            right: 100%;
        }
        &:after {
            left: 100%;
        }
    }
    .av {
        padding: 60px 0 0;
        min-height: 106px;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 50px auto;
        color: @brand-primary;
        font-size: 1.13rem;
        font-weight: 700;
        line-height: 1.33em;
        text-align: center;
        .content {
            padding: 0 40px 10px;
        }
        &:nth-child(1) {
            background-image: url(../img/picto-professionnel.png);
        }
        &:nth-child(2) {
            background-image: url(../img/picto-consultation.png);
        }
        &:nth-child(3) {
            background-image: url(../img/picto-search.png);
        }
        & + .av {
            .content {
                border-left: 1px solid #d7d7d7;
            }
            @media (max-width: @screen-xs-max) {
                margin-top: 30px;
                .content {
                    border: 0;
                }
            }
        }
    }
    .paiements {
        color: #58585a;
        text-align: center;
    }
    .paiement {
        display: inline-block;
        vertical-align: middle;
        &:not(.stripe) {
           img {
                height: auto;
                max-height: 40px;
                width: auto;
                max-width: 60px;
            } 
        }
        & + .paiement {
            margin-left: 20px;
        }
        &.stripe {
            margin-left: 40px;
            @media (max-width: @screen-xs-max) {
                display: block;
                margin-top: 20px;
                margin-left: 0;
                img {
                    margin: 0 auto;
                }
            }
            img {
                width: 145px;
            }
        }
    }
}