// Home
#slider {
  overflow: hidden;
  .cycle-slideshow {
    position: relative;
    height: 420px;
    width: 100%;
    .slide {
      position: absolute;
      z-index: 0;
      padding: 0 15px;
      height: 420px;
      width: 100%;
      background-color: #000;
      border: 8px solid #fff;
      overflow: hidden;
      .content {
        position: absolute;
        z-index: 5;
        bottom: 90px;
        left: 0;
        width: 100%;
        text-align: center;
        h2 {
          margin: 0 0 10px;
          color: #fff;
          font-size: 3rem;
          line-height: 1em;
          @media (max-width: @screen-xs-max) {
            font-size: 1.88rem;
          }
        }
        p {
          margin: 0 0 25px;
          color: #fff;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1em;
        }
      }
      .bg {
        opacity: 0.6;
      }
    }
    .cycle-pager {
      position: absolute;
      z-index: 110;
      bottom: 40px;
      left: 0;
      width: 100%;
      font-size: 0;
      text-align: center;
      span {
        display: inline-block;
        vertical-align: middle;
        height: 8px;
        width: 8px;
        background-color: #fff;
        border-radius: 50%;
        text-indent: -9999px;
        cursor: pointer;
        & + span {
          margin-left: 8px;
        }
        &.cycle-pager-active {
          background-color: #fedcd3;
        }
      }
    }
  }
}

#annuaire {
  margin-top: -120px;
  padding-top: 120px;
  padding-bottom: 35px;
  background-color: #ffd1d8;
  h1,
  h2 {
    margin: 25px 0 30px;
    text-align: center;
  }
  .form-group {
    .clearfix;
    float: left;
    margin: 0 !important;
    @media (max-width: @screen-sm-max) {
      float: none;
      text-align: center;
    }
    label {
      float: left;
      margin: 0 10px 0 0;
      font-weight: 400;
      @media (max-width: @screen-sm-max) {
        display: inline-block;
        float: none;
      }
    }
    .form-control {
      float: left;
      width: 180px;
      max-width: 100%;
      border: 0;
      &#postcode {
        width: 140px;
      }
      @media (max-width: @screen-sm-max) {
        display: inline-block;
        float: none;
      }
    }
    & + .form-group {
      margin-left: 20px !important;
      @media (max-width: @screen-sm-max) {
        margin-top: 20px !important;
        margin-left: 0 !important;
      }
    }
  }
  button[type="submit"] {
    margin-left: 25px;
    @media (max-width: @screen-sm-max) {
      margin-top: 25px;
      margin-left: 0;
    }
  }
  form {
    @media (max-width: @screen-sm-max) {
      text-align: center;
    }
  }
}

#conseils {
  padding-top: 35px;
  padding-bottom: 30px;
  .headTitle {
    margin-top: 0;
  }
  .cat {
    margin: 0;
    color: @brand-primary;
    text-align: center;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

#banner {
  margin-bottom: 70px;
}

#events {
  padding-top: 30px;
  padding-bottom: 40px;
  .headTitle {
    margin-top: 0;
  }
  .more {
    margin-top: 30px;
    text-align: center;
  }
}

#psys {
  padding-top: 50px;
  padding-bottom: 18px;
  overflow: hidden;

  .headTitle {
    margin-top: 0;
  }

  .pin_psy {
    display: flex;
    flex-direction: column;
    gap: 16px 32px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4px;
    padding-bottom: 36px;
    max-width: 660px;

    @media (min-width: @screen-sm-min) {
      flex-direction: row;
      align-items: flex-start;
    }

    .avatar {
      @media (min-width: @screen-sm-min) {
        width: 134px;
      }

      .user-picture {
        position: relative;
        border: 3px solid #f7f6f4;
        border-radius: 50%;
        width: 134px;
        max-width: 100%;
        height: 134px;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .text {
      @media (min-width: @screen-sm-min) {
        flex: 1 0 0;
        padding-top: 20px;
      }

      .title {
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .name {
        margin-top: 0;
        margin-bottom: 0;
        color: @brand-primary;
        font-size: 1.5rem;

        a {
          color: inherit;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .locality {
        margin-bottom: 0;
        color: @brand-secondary;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.6667;
      }

      .tags {
        margin-bottom: 14px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .type,
      .public {
        margin-bottom: 6px;
        color: @brand-secondary;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.25;

        &:last-child {
          margin-bottom: 0;
        }

        strong {
          color: @text-color;
          font-weight: 700;
        }

        a {
          color: inherit;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .presentation {
        margin-bottom: 18px;
        color: @text-color;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.5;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .btn {
        padding: 7px 30px;
        font-size: 0.875rem;
        line-height: 1.4286;
      }
    }
  }

  .psys {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: ~"calc((100vw - 100%) * -0.5)";
      width: 100vw;
      height: 84px;
      background-color: @brand-secondary;
    }

    > * {
      position: relative;
    }

    .item {
      &.item--psy {
        .imgWrapper {
          img {
            margin-bottom: 25px;
            border-width: 3px;
            max-width: 134px;
          }
        }
        .content {
          padding-left: 5px;
          padding-right: 5px;

          @media (min-width: @screen-sm-min) {
            text-align: left;
          }
        }
        // h3 {
        //   font-size: 0.75rem;
        //   line-height: 1.5;
        // }
        // p {
        //   font-size: 0.75rem;
        //   line-height: 1.5;

        //   &.ville {
        //     font-size: 0.5625rem;
        //     line-height: 2;
        //   }
        // }
        // a {
        //   font-size: 0.5625rem;
        //   line-height: 2.6666;
        // }
      }
    }
  }

  .more {
    margin-top: 15px;
    text-align: center;

    .btn {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

#interview {
  padding-top: 20px;
  border-bottom: 120px solid #ffd1d8;
  .item--interview {
    margin: 0;
    .imgWrapper {
      margin-bottom: -75px;
      @media (max-width: @screen-xs-max) {
        margin-bottom: 15px;
      }
    }
  }
}

.cta-hp {
  margin-top: 40px;
}

#pubs {
  @media (max-width: @screen-xs-max) {
    .col-sm-6 {
      & + .col-sm-6 {
        margin-top: 15px;
      }
    }
  }
}

#links {
  padding-top: 50px;
  padding-bottom: 18px;
}
