// Conseil
#comments {
    margin-top: 45px;
}

.moreArticles {
    > h2, > a {
        display: inline-block;
        vertical-align: top;
        @media (max-width: @screen-xs-max) {
            display: block;
        }
    }
    > h2 {
        margin-bottom: 30px;
        @media (max-width: @screen-xs-max) {
            margin-bottom: 10px;
        }
    }
    > a {
        margin: 35px 0 0 15px;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 2.5em;
        text-decoration: underline;
        @media (max-width: @screen-xs-max) {
            margin: 0 0 20px;
        }
        &:hover {
            text-decoration: none;
        }
    }
}